<template>
  <div class='output popUpScrollList'>
    <div class="element materialShadow">
      <div class="geo_help">
        <h2>Android</h2>

        1) {{ x("androidGeoHelpStep1") }}
        <br><br>
        <img src="/static/assets/gfx/geo_help/AndroidSamsungS5Guide1.jpg"
             alt="Android guide step 1">

        <br><br>
        2) {{ x("androidGeoHelpStep2") }}
        <br><br>
        <img src="/static/assets/gfx/geo_help/AndroidSamsungS5Guide2.jpg"
             alt="Android guide step 2">

        <br><br>
        3) {{ x("androidGeoHelpStep3") }}
        <br><br>
        <img src="/static/assets/gfx/geo_help/AndroidSamsungS5Guide3.jpg"
             alt="Android guide step 3">

        <br><br>

        <h2>IOS</h2>

        1) {{ x("iosGeoHelpStep1") }}
        <br><br>
        <img src="/static/assets/gfx/geo_help/IOSGuide1.jpg" alt="iOS guide step 1">
        <br><br>
        <img src="/static/assets/gfx/geo_help/IOSGuide1_1.jpg" alt="iOS guide step 2">

        <br><br>
        2) {{ x("iosGeoHelpStep2") }}
        <br><br>
        <img src="/static/assets/gfx/geo_help/IOSGuide2.jpg" alt="iOS guide step 3">

        <br><br>
        3) {{ x("iosGeoHelpStep3") }}
        <br><br>
        <img src="/static/assets/gfx/geo_help/IOSGuide3.jpg" alt="iOS guide step 4">

        <br><br>
      </div>
    </div>
  </div>
</template>

<script>
  import ComponentEventNames from '@/enums/component-event-names';
  import translate from '../Mixins/Translate';

  export default {
    name: 'geo-help',
    mixins: [translate],
    emits: [ComponentEventNames.didLoad],
    mounted() {
      this.$emit(ComponentEventNames.didLoad);
    },
  };
</script>
